<template>
  <div class="left-section">
    <v-card class="d-flex flex-column mt-5" v-if="volunteer">
      <v-card-title>
        <span
          v-text="volunteer.first_name + ' ' + volunteer.last_name"
          style="color: #472583"
        />
      </v-card-title>
      <v-card-subtitle class="mt-4">
        <span class="subtitle">About Me</span>
      </v-card-subtitle>
      <v-card-text>
        <span v-text="volunteer.about_me" class="text-block" />
      </v-card-text>
      <v-card-subtitle>
        <span class="subtitle">Information</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex align-center" v-if="volunteer.phone">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0A5B8A"
                size="14"
              >
                mdi-phone-classic
              </v-icon>
            </template>
            <span>Phone</span>
          </v-tooltip>
          {{ formatPhoneNumber(volunteer.phone) }}
        </div>
        <div class="d-flex align-center mt-2" v-if="volunteer.email">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0A5B8A"
                size="14"
                >mdi-email</v-icon
              >
            </template>
            <span>Email</span>
          </v-tooltip>
          {{ volunteer.email }}
        </div>
        <!-- address 1 -->
        <div
          class="d-flex align-center mt-2"
          v-if="
            volunteer.city ||
              volunteer.address ||
              volunteer.state ||
              volunteer.zip
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0A5B8A"
                size="14"
                >mdi-map-marker</v-icon
              >
            </template>
            <span>Address</span>
          </v-tooltip>
          {{
            `${volunteer.address}, ${volunteer.city}, ${volunteer.state} ${volunteer.zip}`
          }}
        </div>

        <!-- address 2 -->
        <div
          class="d-flex align-center mt-2"
          v-if="
              volunteer.address2 
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0A5B8A"
                size="14"
                >mdi-map-marker</v-icon
              >
            </template>
            <span>Address</span>
          </v-tooltip>
          {{
            `${volunteer.address2},${volunteer.city}, ${volunteer.state} ${volunteer.zip}`
          }}
        </div>

        <div class="d-flex align-center mt-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0A5B8A"
                size="14"
              >
                mdi-account-group
              </v-icon>
            </template>
            <span>Age group</span>
          </v-tooltip>
          {{ volunteer.age_group }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      volunteer: "volunteer/getVolunteer"
    })
  },
  methods: {
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    }
  }
};
</script>
<style scoped>
.left-section {
  top: 70px;
  max-width: 360px;
  min-width: 360px;
  width: 360px;
  padding-left: 30px;
}
.volunteer-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.subtitle {
  font-size: 16px;
  font-family: "Poppins-SemiBold";
  color: #1e1e1e;
}
</style>
