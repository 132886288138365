var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-section"},[(_vm.volunteer)?_c('v-card',{staticClass:"d-flex flex-column mt-5"},[_c('v-card-title',[_c('span',{staticStyle:{"color":"#472583"},domProps:{"textContent":_vm._s(_vm.volunteer.first_name + ' ' + _vm.volunteer.last_name)}})]),_c('v-card-subtitle',{staticClass:"mt-4"},[_c('span',{staticClass:"subtitle"},[_vm._v("About Me")])]),_c('v-card-text',[_c('span',{staticClass:"text-block",domProps:{"textContent":_vm._s(_vm.volunteer.about_me)}})]),_c('v-card-subtitle',[_c('span',{staticClass:"subtitle"},[_vm._v("Information")])]),_c('v-card-text',[(_vm.volunteer.phone)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#0A5B8A","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone-classic ")])]}}],null,false,3687802678)},[_c('span',[_vm._v("Phone")])]),_vm._v(" "+_vm._s(_vm.formatPhoneNumber(_vm.volunteer.phone))+" ")],1):_vm._e(),(_vm.volunteer.email)?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#0A5B8A","size":"14"}},'v-icon',attrs,false),on),[_vm._v("mdi-email")])]}}],null,false,1502243599)},[_c('span',[_vm._v("Email")])]),_vm._v(" "+_vm._s(_vm.volunteer.email)+" ")],1):_vm._e(),(
          _vm.volunteer.city ||
            _vm.volunteer.address ||
            _vm.volunteer.state ||
            _vm.volunteer.zip
        )?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#0A5B8A","size":"14"}},'v-icon',attrs,false),on),[_vm._v("mdi-map-marker")])]}}],null,false,2709939856)},[_c('span',[_vm._v("Address")])]),_vm._v(" "+_vm._s(`${_vm.volunteer.address}, ${_vm.volunteer.city}, ${_vm.volunteer.state} ${_vm.volunteer.zip}`)+" ")],1):_vm._e(),(
            _vm.volunteer.address2 
        )?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#0A5B8A","size":"14"}},'v-icon',attrs,false),on),[_vm._v("mdi-map-marker")])]}}],null,false,2709939856)},[_c('span',[_vm._v("Address")])]),_vm._v(" "+_vm._s(`${_vm.volunteer.address2},${_vm.volunteer.city}, ${_vm.volunteer.state} ${_vm.volunteer.zip}`)+" ")],1):_vm._e(),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#0A5B8A","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-group ")])]}}],null,false,2439028624)},[_c('span',[_vm._v("Age group")])]),_vm._v(" "+_vm._s(_vm.volunteer.age_group)+" ")],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }